@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/regular";

@font-face {
	font-family: 'Helvetica Neue';
	src: url('../../dist/font/HelveticaNeue-Bold.woff2') format('woff2'),
		url('../../dist/font/HelveticaNeue-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Helvetica Neue';
	src: url('../../dist/font/HelveticaNeue.woff2') format('woff2'),
		url('../../dist/font/HelveticaNeue.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

html {
	background: $black;
	font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-size: 16px;
	line-height: 1;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $white;
	position: relative;
	.bg{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
		z-index: -1;
	}
}

a {
	color: $white;

	@include hover-focus {
		color: $white;
	}
}

h1,h2,h3,h4,h5,ul,ol, p{
	margin: 0;
}

p{
	font-size: 16px;
	line-height: 1.4;
}

.hide{
	display: none !important;
}

.mobile{
	@include tablet-up{
		display: none !important;
	}
}

.desktop{
	@include tablet-down{
		display: none !important;
	}
}

.socials{
	@include flex(row,center,center);
	gap: 20px;
	a{
		font-size: 20px;
	}
}

.split{
	@include flex(column,initial,initial);
	.side{
		flex: 1;
	}
	@include tablet-up{
		@include flex(row,initial,initial);
	}
}

.social{
	@include flex(row,center,center);
	gap: 20px;
	a{
		font-size: 20px;
	}
}

.btn{
	display: inline-block;
	padding: 10px 20px;
	border: solid 2px transparent;
	background-color: transparent;
	border-radius: 0;
	font-size: 16px;
	font-weight: bold;
	&.btn-small{
		padding: 4px 6px;
		font-size: 10px;
	}
	&.btn-outline-white{
		border-color: $white;
		color: $white;
		@include hover-focus{
			background: $white;
			color: $black;
		}
	}
}

form{
	.form-row{
		@include flex(row,center,initial);
		overflow: hidden;
		label{
			position: absolute;
			left: -99999;
			opacity: 0;
		}
		input{
			background-color: transparent;
			border: solid 2px $white;
			color: $white;
			padding: 4px;
			font-weight: bold;
			border-right: none;
			@include hover-focus{
				outline: none;
			}
			&::placeholder{
				color: $white;
			}
		}
	}
}

.form-alert{
	font-size: 8px;
	text-align: center;
	text-transform: uppercase;
	display: none;
	&.error{
		margin-top: 5px;
	}
}

header {
	padding: 20px 0;
	.container{
		.nav{
			@include flex(row,center,center);
			gap: 20px;
			a{
				font-size: 16px;
				font-weight: bold;
				border-bottom: solid 2px transparent;
				@include hover-focus{
					border-bottom-color: $white;
				}
			}
		}
		.social{
			margin-top: 20px;
		}
	}

	@include tablet-up{
		.container{
			.nav{
				gap: 60px;
			}
		}
	}
}

main {
	.container{
		text-align: center;
		height: calc(100vh - 262px);
		section{
			width: 100%;
			height: calc(100vh - 262px);
			@include flex(column,center,center);
			display: none;
			h2{
				font-size: 60px;
				margin-bottom: 20px;
			}
			.content{
				@include flex(column,flex-start,center);
				width: 100%;
				height: calc(100vh - 262px - 32px);
				overflow-y: scroll;
				&::-webkit-scrollbar {
					display: none;
				}
				.music-wrapper{
					.music-item{
						text-align: center;
						height: calc(100vh - 262px - 32px);
						@include flex(column,center,center);
						max-width: 245px;
						img{
							width: 245px;
							height: 245px;
							object-fit: cover;
							margin-bottom: 10px;
						}
						p{
							font-size: 20px;
							font-weight: bold;
						}
					}
				}
				.videos-wrapper{
					.video-item{
						height: calc(100vh - 262px - 32px);
						@include flex(column,center,center);
						width: 100%;
						max-width: 600px;
						img{
							width: 100%;
							aspect-ratio: 6/3;
							margin-bottom: 10px;
							object-fit: cover;
						}
						p{
							font-size: 20px;
							font-weight: bold;
						}
					}
				}

				.events-wrapper{
					height: calc(100vh - 262px - 32px);
					width: 100%;
					max-width: 700px;
					border: solid 2px $white;
					overflow-y: scroll;
					flex-wrap: wrap;
					&::-webkit-scrollbar {
						display: none;
					}
					.event{
						@include flex(row,space-between,center);
						width: 100%;
						padding: 25px 15px;
						font-size: 16px;
						font-weight: bold;
						text-align: left;
						gap: 15px;
						.event-date,.event-links{
							flex: 1;
						}
						.event-location{
							flex: 2;
						}
						.event-links{
							a{
								border-bottom: solid 2px transparent;
								@include hover-focus{
									border-bottom-color: $white;
								}
							}
						}
					}
				}
			}
			.scroll{
				@include flex(column,center,center);
				gap: 0;
				span{
					font-size: 16px;
					text-transform: uppercase;
				}
				i{
					font-weight: 400;
				}
			}
			&.active{
				display: flex;
			}
		}
	}
	@include tablet-up{
		.container{
			height: calc(100vh - 176px);
			section{
				height: calc(100vh - 187px);
				h2{
					font-size: 100px;
				}
				.content{
					height: calc(100vh - 262px - 32px);
					overflow-y: scroll;
				}
			}
		}
	}
}

footer {
	padding: 20px 0;
	.container{
		@include flex(column,center,center);
		gap: 20px;
		text-align: center;
		img{
			height: 12px;
			display: inline-block;
		}
		.copyright{
			p{
				font-size: 8px;
				text-transform: uppercase;
			}
		}
	}

	@include tablet-up{
		.container{
			@include flex(row-reverse,space-between,flex-end);
			gap: 20px;
			.newsletter{
				flex: 1;
				max-width: max-content;
			}
			.copyright{
				flex: 1;
				text-align: left;
			}
		}
	}
}